import Card from 'react-bootstrap/Card';
import {CSSProperties, ElementType} from "react";

interface UniCardsImgProps {
    /** You can use a custom element type for this component. */
    as?:ElementType<any> | undefined
    /** Defines image position inside the card. **/
    variant?:'top' | 'bottom'
    /** Set src on Element */
    src?:string
    /** Set custom Classes on Element */
    class?:string
    /** Set id on Element in DOM */
    id?: string,
    /** Set inline style for Element */
    style?:CSSProperties | undefined
}

export const UniCardsImg = (props:UniCardsImgProps) => {

    return(
        <>
            <Card.Img as={props.as} src={props.src} variant={props.variant} id={props.id} className={props.class}  style={props.style} />
        </>
    )
}
