import Form from 'react-bootstrap/Form';

interface UniSelectItemsProps {
    value: string | number | readonly string[] | undefined;
    text: string;
}

interface UniSelectProps {
    /** Set option Items for SelectBox | {value:'example',text:'example'} */
    items: UniSelectItemsProps[];
    /** Set custom Classes on Element */
    class?: string,
    /** Set id on Element in DOM */
    id?: string,
    /** Set DefaultValue on Element */
    defaultValue?: string | number | readonly string[] | undefined
    /** Combine Element with Onchange Function and Event */
    onChange?: (e: any) => void;
    name?: string
}

/**
 * Customize the native select with custom CSS that changes the element’s initial appearance with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/form-control" target="_blank">Form.Label</a><br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <UniForm validated={validated} Submit={handleSubmit}>
 *    <UniForm.FormGroup>
 *         <UniForm.Label text={'text'} />
 *         <UniForm.Select items={[{value:'example',text:'example'},{value:'example2',text:'example2'}]} />
 *    </UniForm.FormGroup>
 * </UniForm>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const UniSelect = (props: UniSelectProps) => {
    let Class: string = props.class === undefined ? 'mb-3' : props.class

    let ID: string = props.id === undefined ? '' : props.id
    return (
        <>
            <Form.Select name={props.name} className={Class} id={ID} aria-label="Default select example"
                         defaultValue={props.defaultValue}
                         onChange={(e) => props.onChange !== undefined ? props.onChange(e) : ''}>
                {props.items.map((item, index) =>
                    <option value={item.value}>{item.text}</option>
                )}

            </Form.Select>
        </>
    )
}

export default UniSelect;
