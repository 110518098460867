import Form from 'react-bootstrap/Form';
import InfoButton from "../../InfoButton";
import React from "react";

interface UniLabelProps {
    /** Set Label text on Element */
    text: string,
    /** Set custom Classes on Element */
    class?: string,
    /** Set for on Element in DOM */
    for?: string
    InfoButton?: {
        display: boolean
        text: string
    }
}


/**
 * Create Label form text with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/form-control" target="_blank">Form.Label</a><br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <UniForm validated={validated} Submit={handleSubmit}>
 *    <UniForm.FormGroup>
 *         <UniForm.Label text={'text'} />
 *         <UniForm.FormControl type={'type'} name={'name'} placeholder={'placeholder'}  />
 *    </UniForm.FormGroup>
 * </UniForm>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const UniLabel = (props: UniLabelProps) => {

    return (
        <>
            <Form.Label className={props.class}
                        htmlFor={props.for}>
                {props.InfoButton !== undefined && props.InfoButton.display === true
                    ? <><InfoButton.Wrapper>
                        <span>{props.text}</span><InfoButton
                        text={props.InfoButton.text}
                        icon="info"
                    />
                    </InfoButton.Wrapper></>
                    : props.text
                }</Form.Label>
        </>
    )
}

export default UniLabel;
