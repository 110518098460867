import Button from 'react-bootstrap/Button'
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { SpinnerJSX } from '../../../../@WUM/core/component/const/Spinner'
import { ApiURL } from '../../../../@WUM/core/Elements/index'
// @ts-ignore
import { Notification } from 'create-notifications/notfication'
import {ReplaceHtmlEntities} from "../../../../@WUM/core/component/const/ReplaceHtmlEntities";

const DynamikDetail = (props: {
    Json_mapping?: Boolean;
    Mapping_Data?:any;
    value: any
    forms?: any
    response?: any
    saveRoute?: any
    addedForm?: any
    paramsAddedForm?: any
    addedForm2?: any
    addedForm3?: any
    paramsAddedForm2?: any
    paramsAddedForm3?: any
    addedButton?:any
    Buttonhref?:any
}) => {
    const { id } = useParams<{ id: string }>()
    const history = useNavigate()
    const Store = useSelector((state: any) => state)
    const response2 = ReplaceHtmlEntities(props.response(id))


    const addedF =
        props.addedForm !== undefined &&
        props.addedForm !== null &&
        props.paramsAddedForm !== undefined &&
        props.paramsAddedForm !== null
            ? props.addedForm(id)
            : props.addedForm !== undefined && props.addedForm !== null
            ? props.addedForm()
            : ''
    const addedF2 =
        props.addedForm2 !== undefined &&
        props.addedForm2 !== null &&
        props.paramsAddedForm2 !== undefined &&
        props.paramsAddedForm2 !== null
            ? props.addedForm2(id)
            : props.addedForm2 !== undefined && props.addedForm2 !== null
            ? props.addedForm2()
            : ''

    const addedF3 =
        props.addedForm3 !== undefined &&
        props.addedForm3 !== null &&
        props.paramsAddedForm3 !== undefined &&
        props.paramsAddedForm3 !== null
            ? props.addedForm3(id)
            : props.addedForm3 !== undefined && props.addedForm3 !== null
                ? props.addedForm3()
                : ''
    if (response2.length > 0) {
        const ButtonHolder = <p style={{textAlign:'center',float: 'left',width: '85%'}}>
                <Link className={'btn btn-primary'} to={(response2[0].media !== undefined && response2[0].media !== null ? response2[0].media.cdn_url: '')}
                      target="_blank" download>
                <span className="material-icons align-bottom">
                file_download
                </span> Rechnung Herunterladen</Link></p>
        const send = (e: any) => {
            e.preventDefault()
            let data = new FormData(e.target)
            let value = Object.fromEntries(data.entries())
            let raw;
            if (props.Json_mapping !== undefined) {
                raw  = JSON.stringify(props.Mapping_Data(value));
            } else {
                raw  = JSON.stringify(value)
            }
            let root = ApiURL()
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }
            fetch(root + '' + props.saveRoute + '' + id + '/', requestOptions)
                .then((response) => response.text())
                .then((result) => afterSend('success'))
                .catch((error) => afterSend('error'))
        }

        const afterSend = (state: any) => {
            if (state === 'success') {
                Notification({
                    reload: true,
                    type: 'success',
                    mode: 'classic',
                    title: { text: 'Success' },
                    text: { text: 'Saved successfully' },
                    insert: 'center right',
                    duration: 4
                })
            } else if (state === 'error') {
                Notification({
                    reload: true,
                    type: 'danger',
                    mode: 'classic',
                    title: { text: 'Error' },
                    text: { text: 'Sorry, an error has occurred' },
                    insert: 'center right',
                    duration: 4
                })
            }
        }

        return (
            <>
                <div style={{ backgroundColor: 'white', padding: '15px' }}>
                    <Form id={'form001'} onSubmit={(e) => send(e)}>
                        {props.addedForm !== undefined &&
                        props.addedForm !== null &&
                        props.addedForm2 !== undefined &&
                        props.addedForm2 !== null &&
                            props.addedForm3 !== undefined &&
                            props.addedForm3 !== null
                            ? props.forms(response2, 'Detail', addedF, addedF2,addedF3)
                            : (props.addedForm !== undefined &&
                            props.addedForm !== null &&
                            props.addedForm2 !== undefined &&
                            props.addedForm2 !== null
                            ? props.forms(response2, 'Detail', addedF, addedF2)
                            : (props.addedForm !== undefined &&
                                    props.addedForm !== null  ? props.forms(response2, 'Detail', addedF)
                                    :props.forms(response2, 'Detail')))}
                        <div
                            style={{
                                backgroundColor: 'white',
                                padding: '15px'
                            }}
                        >
                            <Button
                                variant="light"
                                style={{ float: 'left', marginLeft: '15px' }}
                                onClick={() => history(-1)}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.back"
                                />
                            </Button>
                            {(props.addedButton !== undefined && response2[0].media !== undefined && response2[0].media !== null ? ButtonHolder : <></>)}
                            <Button
                                variant="success"
                                style={{ float: 'right', marginRight: '15px' }}
                                type={'submit'}
                                id={'sendButton'}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.save"
                                />
                            </Button>
                            <br style={{ clear: 'both' }} />
                        </div>
                    </Form>
                </div>
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default DynamikDetail
