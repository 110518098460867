import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";

interface UniFloatingLabelProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** Set Label text on Element */
    text:string,
    /** Set custom Classes on Element */
    class?:string,
    /** Set id on Element in DOM */
    id?:string
}

/**
 * Create beautifully simple form labels that float over your input fields with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/floating-labels" target="_blank">FloatingLabel</a> for FormControl within a FormGroup or an InputGroup<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <UniForm validated={validated} Submit={handleSubmit}>
 *   <UniForm.FormGroup>
 *       <UniForm.FloatingLabel text={'Your label text'} />
 *       <UniForm.FormControl type={'text'} name={'name'} placeholder={'placeholder'}  />
 *   </UniForm.FormGroup>
 * </UniForm>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const Wk24FloatingLabel = (props:UniFloatingLabelProps) => {

    let Class:string = props.class === undefined ? 'mb-3' : props.class

    let ID:string = props.id === undefined ? '' : props.id

    return(
        <>
            <FloatingLabel
                controlId={ID}
                label={props.text}
                className={Class}
            >
                {props.children}
            </FloatingLabel>
        </>
    )
}

export default Wk24FloatingLabel;
