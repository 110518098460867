import React from 'react'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { modalvariants, setvariants } from './variants'
import { ProductVariantsDetailApiType } from '../../../../API/request/Product/ProductVariantsApiDetail.interface'
import { number } from '../../../../../../../@WUM/core/component/const/number'
import {
    notify_copy_variants,
    notify_save_intvall_reload
} from '../../../../../../../@WUM/core/component/const/notification.service'
import Form from 'react-bootstrap/Form'
import Paper from '@material-ui/core/Paper'
import { ControlCheckedVariantPic, IntlMessage, IntlMessageValue } from '../../../../../../../@WUM/core/Elements/index'
import { displayElement } from '../../../../../../../@WUM/core/component/const/displayElement'

function Content(
    response: any,
    loadVariants: boolean,
    saveLoadVariants: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void,
    loadVariantsValue: any,
    saveLoadVariantsValue: (
        value:
            | ((
                  prevState: ProductVariantsDetailApiType[]
              ) => ProductVariantsDetailApiType[])
            | ProductVariantsDetailApiType[]
    ) => void,
    modalvariantsShow: any,
    setModalvariantsShow: any,
    variantsValue: any,
    savevariantsValue: (
        value: ((prevState: string) => string) | string
    ) => void,
    variantstype: any,
    savevariantstype: any,
    Store: any
) {
    if (response.length > 0) {
        if (loadVariants === true) {
            return (
                <>
                    <Row>
                        <Col>
                            <div className={'float-end'}>
                                <Button
                                    type={'button'}
                                    variant="success"
                                    onClick={() =>
                                        setvariants(
                                            setModalvariantsShow,
                                            savevariantsValue,
                                            'new',
                                            Store
                                        )
                                    }
                                >
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.create_new_variant"
                                    />
                                </Button>
                            </div>
                            <br style={{ clear: 'both' }} />
                        </Col>
                    </Row>
                    <Row>
                        {buildVariants(
                            loadVariantsValue,
                            setModalvariantsShow,
                            savevariantsValue,
                            Store
                        )}
                    </Row>
                    {modalvariants(
                        loadVariants,
                        loadVariantsValue,
                        modalvariantsShow,
                        setModalvariantsShow,
                        variantsValue,
                        response[0].id,
                        Store
                    )}
                </>
            )
        } else {
            return (
                <>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">
                            <IntlMessage
                                Store={Store}
                                messageId="common.loading"
                            />
                        </span>
                    </Spinner>
                </>
            )
        }
    }
}

export default Content

function buildVariants(
    variants: any,
    setModalvariantsShow: any,
    savevariantsValue: any,
    Store: any
) {
    if (variants.length > 0) {
        let singleVariants = []
        for (let v = 0; v < variants.length; v++) {
            singleVariants.push(
                table(
                    variants[v],
                    setModalvariantsShow,
                    savevariantsValue,
                    Store
                )
            )
        }

        return singleVariants
    }
}

function table(
    item: any,
    setModalvariantsShow: any,
    savevariantsValue: any,
    Store: any
) {
    let status
    let statusLabel
    if (item.enabled === 1) {
        status = 'success'
        statusLabel = <IntlMessage Store={Store} messageId="available" />
    } else {
        status = 'danger'
        statusLabel = <IntlMessage Store={Store} messageId="available.not" />
    }

    let statusLager
    let statusLagerLabel
    if (item.lagerhaltung === 1) {
        statusLager = 'success'
        statusLagerLabel = (
            <IntlMessage Store={Store} messageId="common.activated" />
        )
    } else {
        statusLager = 'danger'
        statusLagerLabel = (
            <IntlMessage Store={Store} messageId="common.deactivated" />
        )
    }

    let shipping: string = ''
    if (item.shippingCategory !== null) {
        for (let s = 0; s < item.shippingCategory.length; s++) {
            let end = item.shippingCategory.length - 1
            if (s === end) {
                shipping += item.shippingCategory[s].name
            } else {
                shipping += item.shippingCategory[s].name + ' '
            }
        }
    }
    let Unit: string = ''
    if (localStorage.getItem('Unit') !== null) {
        let unitHolder = JSON.parse(localStorage.getItem('Unit') as string)

        for (let s = 0; s < unitHolder.length; s++) {
            if (item.pricing.price_base_unit === unitHolder[s].code) {
                Unit = unitHolder[s].name
            }
        }
    }

    return (
        <>
            <Col xl={3} md={4} sm={6}>
                <Paper elevation={16}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {' '}
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.name"
                                    />
                                </TableCell>
                                <TableCell>
                                    {' '}
                                    {item.translations[0].name}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {' '}
                                    <IntlMessage
                                        Store={Store}
                                        messageId="article.no"
                                    />
                                </TableCell>
                                <TableCell> {item.code}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.status"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Badge bg={status}>
                                        <span> {statusLabel} </span>
                                    </Badge>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {' '}
                                    <IntlMessage
                                        Store={Store}
                                        messageId="article.price.euro"
                                    />
                                </TableCell>
                                <TableCell className="price">
                                    {' '}
                                    {number('de', item.pricing.price / 100)} EUR
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {' '}
                                    <IntlMessage
                                        Store={Store}
                                        messageId="stock.keeping"
                                    />
                                </TableCell>

                                <TableCell>
                                    <Badge bg={statusLager}>
                                        <span> {statusLagerLabel} </span>
                                    </Badge>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    {' '}
                                    <IntlMessage
                                        Store={Store}
                                        messageId="sale.volume"
                                    />
                                </TableCell>

                                <TableCell>
                                    {' '}
                                    {item.pricing.price_base_factor} {Unit}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {' '}
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.shipping"
                                    />
                                </TableCell>
                                <TableCell>
                                    <label className={'versandLabel'}>
                                        {shipping}
                                    </label>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.set.variant.pic.label"
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button
                                        type={'button'}
                                        variant="success"
                                        onClick={() =>
                                            displayElement(
                                                'Content' + item.id,
                                                'block'
                                            )
                                        }
                                    >
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.set.variant.pic.button"
                                        />
                                    </Button>
                                    <br />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    className="table__actions"
                                    colSpan={2}
                                >
                                    <Button
                                        type={'button'}
                                        variant="success"
                                        onClick={() =>
                                            setvariants(
                                                setModalvariantsShow,
                                                savevariantsValue,
                                                item.code,
                                                Store
                                            )
                                        }
                                    >
                                        <IntlMessage
                                            Store={Store}
                                            messageId="common.edit"
                                        />
                                    </Button>
                                    <Button
                                        style={{ marginLeft: '10px' }}
                                        type={'button'}
                                        variant="success"
                                        onClick={() => makeCopy(item, Store)}
                                    >
                                        <IntlMessage
                                            Store={Store}
                                            messageId="copy"
                                        />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Col>
            <Paper
                id={'Content' + item.id}
                className={'modalviewContent'}
                style={{ display: 'none', width: '450px', zIndex: 9999 }}
            >
                <div>
                    <br />
                    <Button
                        type={'button'}
                        variant={'danger'}
                        onClick={(e) =>
                            saveVariant(
                                item.product_id,
                                e,
                                false,
                                true,
                                item.id
                            )
                        }
                    >
                        Vorschau Bild entfernen
                    </Button>
                    <Button
                        className={'float-end'}
                        type={'button'}
                        variant={'primary'}
                        onClick={() =>
                            displayElement('Content' + item.id, 'none')
                        }
                    >
                        Schließen
                    </Button>
                    <div style={{ clear: 'both' }} />
                </div>
                <hr />

                <Row style={{ padding: '10px' }}>
                    {SetVariantPic(item.id, item.code, Store)}
                </Row>
            </Paper>
        </>
    )
}

function makeCopy(element: any, Store: any) {
    notify_copy_variants(
        IntlMessageValue('de', 'variants.make.copy', Store),
        Store
    )
    localStorage.setItem('copyVariants', JSON.stringify(element))
}

const SetVariantPic = (variantID: any, artikenummer: any, Store: any) => {
    let images: any = JSON.parse(localStorage.getItem('images') as string)

    let ImageHolder = []
    for (let element = 0; element < images.length; element++) {
        if (images[element].path.split('.')[1] === 'mp4') {
        } else if (images[element].path.split('.')[1] === 'pdf') {
        } else {
            let checked =
                variantID === images[element].variant_id ? true : false

            let CheckoxClass = ''

            let readonly =
                images[element].variant_id !== null &&
                images[element].variant_id !== 0
                    ? checked === false
                        ? true
                        : false
                    : false
            let checkbox
            if (checked === false) {
                let CheckboxInput = (
                    <Form.Check
                        className={'variandClass' + variantID}
                        name={'variantID'}
                        id={'checkbox' + images[element].id + 'variantID'}
                        value={variantID}
                        onChange={(e) =>
                            saveVariant(
                                images[element].id,
                                e,
                                readonly,
                                false,
                                false
                            )
                        }
                        aria-label="option 1"
                    />
                )
                if (
                    images[element].variant_id !== null &&
                    images[element].variant_id !== 0
                ) {
                    let Label =
                        images[element].variant_id === variantID ? (
                            <>
                                <p className={'pwhite'}>
                                    {IntlMessageValue(
                                        'de',
                                        'common.set.variant.pic.ative',
                                        Store
                                    )}
                                    <br />
                                    {artikenummer}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className={'pwhite'}>
                                    {IntlMessageValue(
                                        'de',
                                        'common.set.variant.pic.use',
                                        Store
                                    )}
                                    <br />
                                    {artikenummer}
                                </p>
                            </>
                        )
                    CheckboxInput = Label
                    CheckoxClass = 'divoverlay'
                }
                checkbox = (
                    <>
                        <Col
                            xl={3}
                            md={3}
                            sm={3}
                            className={CheckoxClass}
                            style={{
                                marginBottom: '10px',
                                marginRight: '10px'
                            }}
                            id={'media' + images[element].id}
                        >
                            <div className={'img-thumbnail'}>
                                <img
                                    alt={images[element].id}
                                    width={77.74}
                                    src={
                                        (process.env.REACT_APP_MODE === 'LOCAL'
                                            ? process.env
                                                  .REACT_APP_CDN_URL_LOCAL
                                            : process.env.REACT_APP_CDN_URL) +
                                        '' +
                                        images[element].path
                                    }
                                />
                            </div>
                            <Form.Group
                                controlId={'checkbox' + images[element].id}
                                key={'checkbox' + images[element].id}
                                className="mb-3"
                                style={{ textAlign: 'center' }}
                            >
                                {CheckboxInput}
                            </Form.Group>
                        </Col>
                    </>
                )
            } else {
                let CheckboxInput = (
                    <Form.Check
                        checked={checked}
                        className={'variandClass' + variantID}
                        name={'variantID'}
                        id={'checkbox' + images[element].id + 'variantID'}
                        value={variantID}
                        readOnly={true}
                        onChange={(e) =>
                            saveVariant(
                                images[element].id,
                                e,
                                readonly,
                                false,
                                false,
                                Store
                            )
                        }
                        aria-label="option 1"
                    />
                )
                if (
                    images[element].variant_id !== null &&
                    images[element].variant_id !== 0
                ) {
                    let Label =
                        images[element].variant_id === variantID ? (
                            <>
                                <p className={'pwhite'}>
                                    {IntlMessageValue(
                                        'de',
                                        'common.set.variant.pic.ative',
                                        Store
                                    )}
                                    <br />
                                    {artikenummer}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className={'pwhite'}>
                                    {IntlMessageValue(
                                        'de',
                                        'common.set.variant.pic.use',
                                        Store
                                    )}
                                    <br />
                                    {artikenummer}
                                </p>
                            </>
                        )
                    CheckboxInput = Label
                    CheckoxClass = 'divoverlayactive'
                }
                checkbox = (
                    <>
                        <Col
                            xl={3}
                            md={3}
                            sm={3}
                            className={CheckoxClass}
                            style={{
                                marginBottom: '10px',
                                marginRight: '10px'
                            }}
                            id={'media' + images[element].id}
                        >
                            <div className={'img-thumbnail'}>
                                <img
                                    alt={images[element].id}
                                    width={77.74}
                                    src={
                                        (process.env.REACT_APP_MODE === 'LOCAL'
                                            ? process.env
                                                  .REACT_APP_CDN_URL_LOCAL
                                            : process.env.REACT_APP_CDN_URL) +
                                        '' +
                                        images[element].path
                                    }
                                />
                            </div>
                            <Form.Group
                                controlId={'checkbox' + images[element].id}
                                key={'checkbox' + images[element].id}
                                className="mb-3"
                                style={{ textAlign: 'center' }}
                            >
                                {CheckboxInput}
                            </Form.Group>
                        </Col>
                    </>
                )
            }

            ImageHolder.push(<>{checkbox}</>)
        }
    }

    return ImageHolder
}

const saveVariant = (
    id: any,
    e: any,
    readonly: boolean,
    unset?: any,
    variant?: any,
    productID: any = null,
    Store: any = null
) => {
    if (readonly === false) {
        let variant_id: string | Blob
        if (unset !== undefined && unset === true && variant !== false) {
            variant_id = variant
        } else {
            variant_id = e.target.value
        }
        let api_URL
        if (process.env.REACT_APP_MODE === 'LOCAL') {
            api_URL = process.env.REACT_APP_API_URL_LOCAL
        } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
            api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT
        } else if (process.env.REACT_APP_MODE === 'LIVE') {
            api_URL = process.env.REACT_APP_API_URL_LIVE
        } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
            api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD
        }
        var formdata = new FormData()
        formdata.append('variant_id', variant_id)
        if (unset !== undefined && unset === true && variant !== false) {
            formdata.append('delete', 'true')
            formdata.append('variant_id', variant_id)
        }
        //let token = localStorage.getItem('token');
        fetch(api_URL + 'Single/images/variants/' + id + '/', {
            method: 'POST',
            body: formdata,
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem('token') as string
                )}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                notify_save_intvall_reload(Store)
                ControlCheckedVariantPic(
                    'variandClass' + variant_id,
                    'checkbox' + id + 'variantID'
                )
                setTimeout(() => window.location.reload(), 3000)
            })
            .catch((e) => {
                //error(e);
                setTimeout(() => window.location.reload(), 3000)
            })
    }
}
