import {CSSProperties, JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Form from 'react-bootstrap/Form';
import UniLabel from "./Elements/Label";
import UniFormGroup from "./Elements/FormGroup";
import UniSelect from "./Elements/Select";
import UniFloatingLabel from "./Elements/FloatingLabel";
import UniInputGroupText from "./Elements/InputGroupText";
import UniFormText from "./Elements/FormText";
import UniFormControl from "./Elements/FormControl";
import UniControlFeedback from "./Elements/ControlFeedback";
import UniChecks from "./Elements/Check";
import UniInputGroup from "./Elements/InputGroup";
import ToggleSelect from "./Elements/ToggleSelect";

export interface UniFormProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** Passes a Boolean whether validation should be carried out. */
    validated: boolean,
    /** Passes the function for the submit event. */
    Submit: (event: any) => void
    /** Set custom Classes on Element */
    className?:string
    /** Set id on Element in DOM */
    id?: string
    /** Set inline style for Element */
    style?:CSSProperties | undefined
}

/**
 * UniForm <br/>
 * ```tsx
 * <UniForm validated={validated} Submit={handleSubmit}>
 *          .....
 * </UniForm>
 * ```
 * Initializes the form. <br/>
 * The following modules can be used within the form
 * <table><tr><th>Module</th><th>Namespace</th></tr>
 * <tr><td>UniInput</td><td>UniForm.FormControl</td></tr>
 * <tr><td> UniLabel</td><td>UniForm.Label</td></tr>
 * <tr><td> UniFormGroup</td><td> UniForm.FormGroup </td></tr>
 * <tr><td> UniFormControl</td><td> UniForm.InputGroup</td></tr>
 * <tr><td> UniInputGroupText</td><td> UniForm.InputGroupText</td></tr>
 * </table><br/>
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const UniForm = (props: UniFormProps) => {

    return <Form noValidate validated={props.validated} onSubmit={props.Submit} id={props.id} className={props.className} style={props.style}>{props.children}</Form>

}

UniForm.FormControl = UniFormControl
UniForm.Label = UniLabel
UniForm.FormGroup = UniFormGroup
UniForm.InputGroup = UniInputGroup
UniForm.InputGroupText = UniInputGroupText
UniForm.Select = UniSelect
UniForm.FloatingLabel = UniFloatingLabel
UniForm.Text = UniFormText
UniForm.ControlFeedback = UniControlFeedback
UniForm.Checks = UniChecks
UniForm.ToggleSelect = ToggleSelect;

export default UniForm;

