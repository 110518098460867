import ListGroup from 'react-bootstrap/ListGroup';
import {Month, setMonth} from "../../../@WUM/core/component/const/WUMCalender";
import {useSelector} from "react-redux";
import React, {useState} from "react";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ResponseAllTResponsibility} from "../../ticketsystem/API/response/ticket";
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {ResponseAllPlaner} from "../API/response/worktimetracking";
import {init2} from "../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken} from "../../../@WUM/core/component/const/getToken";
import {ApiURL, IntlMessageValue} from "../../../@WUM/core/Elements/index";
const Planer = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)
    const [year, setYear] = useState(2024);
    const [months, setMonths] = useState(0);
    const [working, setWorking] = useState(true);
    const [vacation, setVacation] = useState(true);
    const [sick, setSick] = useState(true);
    const [editMode,setEditMode] = useState(false);
    const [fetchData, setFetchData] = useState(0);
    const daysInMonth = (year:any, month:any) => new Date(year, month, 0).getDate();
    const NameIndays = (year:any, month:any,day:any) => new Date(year, month, day).getDay();

    const response = ResponseAllPlaner(fetchData);


    var days = ['day.short.sunday', 'day.short.monday', 'day.short.tuesday', 'day.short.wednesday', 'day.short.thursday', 'day.short.friday', 'day.short.saturday'];
    let TheadHolder = [];

    for(let d=1;d<=daysInMonth(year, months);d++){
        TheadHolder.push(
            <th>{IntlMessageValue('de',days[NameIndays(year, months,d)],Store)} <br/> {d}</th>
        )
    }
    let AllUSer = ResponseAllTResponsibility(fetchData)

    const handlesubmit = (e:any,year:any,month:any,day:any,userid:any,id:any=0) => {

        if(e.target.checked === true) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+ getToken());

            var raw = JSON.stringify(
                {
                    "year": year,
                    "month": month,
                    "day": day,
                    "date": year+'-'+month+'-'+day,
                    "userid": userid,
                    "comment":  (document.getElementById('SelectCommit') as HTMLSelectElement).value,
                    "mode": (document.getElementById('SelectMode') as HTMLSelectElement).value
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };

            fetch(ApiURL() +"work/planer/add/", requestOptions)
                .then(response => response.text())
                .then(result => {successForm(result)})
                .catch(error => errorForm(error));
        } else {
            var myHeaderss = new Headers();
            myHeaderss.append("Content-Type", "application/json");
            myHeaderss.append("Authorization", "Bearer "+ getToken());

            var raws =  '';

            var requestOptionss = {
                method: 'DELETE',
                headers: myHeaderss,
                body: raws,
            };

            fetch(ApiURL() +"work/planer/delete/"+id+'/', requestOptionss)
                .then(response => response.text())
                .then(result => {successForm(result)})
                .catch(error => errorForm(error));
        }

    };

    const successForm = (result:any) => {
        setFetchData(Date.now())
    }

    const errorForm = (error:any)=> {
    }

    /* let Data = [
         {year:2023,month:2,day:1,work:28800000,break:1800000,userid:1,vacation:0,sick:0,mode:'working'},
         {year:2023,month:2,day:2,work:28800000,break:3600000,userid:18,vacation:0,sick:0,mode:'working'},
         {year:2023,month:2,day:3,work:28800000,break:3600000,userid:18,vacation:1,sick:0,mode:'vacation'},
         {year:2023,month:2,day:6,work:28800000,break:3600000,userid:18,vacation:0,sick:1,mode:'sick'},
         {year:2023,month:2,day:7,work:28800000,break:3600000,userid:18,vacation:0,sick:0,mode:'working'},
         {year:2023,month:2,day:8,work:28800000,break:3600000,userid:18,vacation:0,sick:0,mode:'working'},
         {year:2023,month:2,day:9,work:28800000,break:3600000,userid:18,vacation:0,sick:0,mode:'working'},
         {year:2023,month:2,day:10,work:28800000,break:3600000,userid:18,vacation:0,sick:0,mode:'working'}
     ]*/
    let Data: string | any[] = [];
    if (typeof response !== "boolean" && response?.length > 0) {
        Data = response
    }

    const HoverElement = (prefex:any,id:any,clear:any=false) => {
        let tmp = document.getElementById(prefex+''+id) as HTMLDivElement;
        if(tmp !== null) {
            if(clear === false) {
                tmp.style.display = 'block'
            } else {
                tmp.style.display = 'none'
            }

        }
    }

    const ProgressState = (givenNumber:any) => {
        if(givenNumber >= 0 && givenNumber <= 30) {
            return 'danger'
        } else if(
            givenNumber  >= 31 && givenNumber <= 70
        ) {
            return 'warning'
        } else if(
            givenNumber >= 71 && givenNumber <= 100
        ) {
            return 'success'
        } else {
            return 'danger'
        }
    }

    const setMode = () => {

    }

    const Mode = (mode:any) => {
        switch (mode) {
            case 'vacation':
                if (vacation === true) {
                    return true;
                } else {
                    return false;
                }
            case 'sick':
                if (sick === true) {
                    return true;
                } else {
                    return false;
                }
            case 'working':
                if (working === true) {
                    return true;
                } else {
                    return false;
                }
            case 'unapologetic':

                return true;

            case 'absent':

                return true;

            case 'training':
                return true;

        }
    }

    const ColorMaker = (name:any) => {

        let CMAp = [
            '#FBB17C', '#666666', '#008000', '#8B0000', '#00008B', '#8800FF'
        ]

        switch (name) {
            case 'vacation':
                return CMAp[0]
            case 'sick':
                return CMAp[1]
            case 'working':
                return CMAp[2]
            case 'unapologetic':
                return CMAp[3]
            case 'absent':
                return CMAp[4]
            case 'training':
                return CMAp[5]
        }


    }

    let USerRow = [];
    let UserHolderOption = [];
    if (typeof response !== "boolean" && response?.length > 0) {
        if (AllUSer !== true && typeof AllUSer !== "boolean" && AllUSer?.length > 0) {

            for (let us = 0; us < AllUSer?.length; us++) {
                let UserHolder = [];
                UserHolderOption.push(
                    <>
                        <option value={AllUSer[us].username}>{AllUSer[us].username}</option>
                    </>
                )

                UserHolder.push(
                    <>
                        <td style={{background:(AllUSer[us].id == localStorage.getItem('userid') ? '#E5F1FD': '')}}>{AllUSer[us].username}</td>
                    </>
                )

                for(let d=1;d<=daysInMonth(year, months);d++){
                    let set = true;
                    for(let da=0;da<Data.length;da++) {
                        if (Data[da].year == year && Data[da].month == months && Data[da].day == d && AllUSer[us].id == Data[da].userid && Mode(Data[da].mode) === true) {

                            set = false;
                            let userid = AllUSer[us].id
                            if(editMode === true ) {
                                UserHolder.push(
                                    <td className={'text-center'} style={{position:'relative',background:(userid == localStorage.getItem('userid') ? '#E5F1FD': '')}}>
                                        <Form.Check className={Data[da].mode}
                                                    onClick={(e)=>handlesubmit(e,year,months,d,userid,Data[da].id)}
                                                    defaultChecked={true}
                                                    label={''}
                                                    name="group1"
                                                    type={'checkbox'}
                                        />
                                    </td>
                                )
                            } else {

                                let workingTime = 0;
                                let BreakTime = 0;
                                if(Data[da].timeEntry !== undefined && Data[da].timeEntry !== null) {
                                    for(let w=0;w<Data[da].timeEntry.length;w++) {
                                        let Dataset = Data[da].timeEntry[w];
                                        if(Dataset.grund == 'Arbeit') {
                                            workingTime = workingTime + (parseInt(Dataset.dauer) * 1000)
                                        } else if(Dataset.grund == 'Pause') {
                                            BreakTime = BreakTime + (parseInt(Dataset.dauer) * 1000)
                                        }
                                    }
                                }

                                UserHolder.push(

                                    <td className={'text-center'} style={{position:'relative',background:(userid == localStorage.getItem('userid') ? '#E5F1FD': '')}}>
                                <span className="material-icons" onMouseLeave={()=>HoverElement(d+'user',userid,true)} onMouseOver={()=>HoverElement(d+'user',userid)} style={{background:ColorMaker(Data[da].mode),borderRadius:'50%',color:ColorMaker(Data[da].mode)}}>
                                    radio_button_unchecked
                                </span>
                                        <Card id={d+'user'+userid} className={'arrowBottom'} style={{ width: '18rem',position:'absolute',top: '-155px',left: '-100px',display:'none',background:'#343232',color:'white' }}>
                                            <Card.Body className={'pb-1 pt-2'}>
                                                <Card.Title>{AllUSer[us].username}</Card.Title>
                                                <Card.Text>
                                                    {
                                                        Data[da].mode === 'working' ?
                                                            <>
                                                                <p className={'text-left  pb-1'}>{IntlMessageValue('de','wtp.work.time',Store)} <span
                                                                    className={'float-end'}>{(workingTime / 3600000).toFixed(2)}h/8h</span><br/>
                                                                    <ProgressBar
                                                                        variant={ProgressState((100 / (8 * 3600000) * workingTime))}
                                                                        now={(100 / (8 * 3600000) * workingTime)}
                                                                        style={{height: '10px'}}/>
                                                                </p><p className={'text-left pb-1'}>{IntlMessageValue('de','wtp.break.time',Store)}<span
                                                                className={'float-end'}>{(BreakTime / 60000).toFixed(2)} min</span>
                                                                <br/>
                                                                <ProgressBar
                                                                    variant={ProgressState((100 / (1 * 3600000) * BreakTime))}
                                                                    now={(100 / (1 * 3600000) * BreakTime)}
                                                                    style={{height: '10px'}}/>
                                                            </p></>
                                                            :
                                                            <>
                                                                {IntlMessageValue('de','wtp.'+Data[da].mode,Store)}<br/>
                                                                { Data[da].comment !== null ?
                                                                    IntlMessageValue('de','wtp.'+Data[da].comment,Store)
                                                                    :''
                                                                }
                                                            </>
                                                    }
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </td>
                                )
                            }
                        }
                    }
                    if(set === true) {
                        let userid = AllUSer[us].id
                        UserHolder.push(
                            <>
                                {editMode === true
                                    ?
                                    <td className={'text-center'} style={{position:'relative',background:(userid == localStorage.getItem('userid') ? '#E5F1FD': '')}}>
                                        <Form.Check
                                            onClick={(e)=>handlesubmit(e,year,months,d,userid)}
                                            label=""
                                            name="group1"
                                            type={'checkbox'}
                                        />
                                    </td>
                                    : <><td style={{background:(userid == localStorage.getItem('userid') ? '#E5F1FD': '')}}></td></>
                                }
                            </>
                        )
                    }

                }
                USerRow.push(
                    <>
                        <tr>
                            {UserHolder}
                        </tr>
                    </>
                )
            }


        }
        const setActiveList = (x:any,e:any) => {

            for(let m=0;m<Month.length;m++) {
                let tmp = document.getElementById(m+'month') as HTMLDivElement;
                if(tmp !== null) {
                    tmp.style.background = 'white';
                }

            }

            let tmps = e.target as HTMLDivElement;
            if(tmps !== null) {
                setMonths(x)
                tmps.style.background = '#B3FFBF';
            }

        }

        let MonthHoler = [];

        for(let x=0;x<Month.length;x++) {
            MonthHoler.push(
                <ListGroup.Item id={x+'month'} style={{background:(months === x ? '#B3FFBF' : 'white')}} onClick={(e)=>setActiveList(x,e)}>{setMonth(x,Store)}</ListGroup.Item>
            )
        }

        const SetterEditmode = () => {
            let tmp = document.getElementById('SelectCommit') as HTMLSelectElement;
            let tmps = document.getElementById('SelectMode') as HTMLSelectElement;
            if(editMode === true) {
                setEditMode(false)
                if(tmp !== null) {
                    tmp.style.display = 'none'
                    tmp.value = ''
                }
                if(tmps !== null) {
                    tmps.style.display = 'none'
                    tmps.value = 'working'
                }

            } else {
                setEditMode(true)

            }
        }

        const Changer = (e:any) => {

            let tmp = document.getElementById('SelectCommit') as HTMLSelectElement;

            if(e.target.value === 'absent') {
                if(tmp !== null) {
                    tmp.style.display = 'block'
                }
            } else {
                if(tmp !== null) {
                    tmp.style.display = 'none'
                    tmp.value = ''
                }
            }
        }



        return(
            <>
                <h3><p className={'float-start'}>Planer</p>
                    <ListGroup horizontal className={'float-start ml-3'} style={{fontSize:'16px'}}>
                        <ListGroup.Item>
                            <span className={'LegendeColorHolder'} style={{background:'#008000'}}></span>{IntlMessageValue('de', 'wtp.working', Store)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span className={'LegendeColorHolder'} style={{background:'#FBB17C'}}></span>{IntlMessageValue('de', 'wtp.vacation', Store)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span className={'LegendeColorHolder'} style={{background:'#666666'}}></span>{IntlMessageValue('de', 'wtp.sick', Store)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span className={'LegendeColorHolder'} style={{background:'#8B0000'}}></span>{IntlMessageValue('de', 'wtp.unapologetic', Store)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span className={'LegendeColorHolder'} style={{background:' #00008B'}}></span>{IntlMessageValue('de', 'wtp.absent', Store)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span className={'LegendeColorHolder'} style={{background:' #8800FF'}}></span>{IntlMessageValue('de', 'wtp.training', Store)}
                        </ListGroup.Item>
                    </ListGroup>
                    <ButtonGroup aria-label="Basic example" className={'float-end'}>
                        <Button style={{background: '#B3FFBF', color: 'black', borderColor: '#B3FFBF'}}>
                        <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                            add
                        </span>
                            {IntlMessageValue('de', 'wtp.button.add_activity', Store)}</Button>
                        <Button variant="primary" onClick={() => SetterEditmode()}>
                        <span className="material-icons" style={{verticalAlign: 'bottom'}}>
                            edit
                        </span>
                            {IntlMessageValue('de', 'wtp.button.edit_activity', Store)}</Button>
                    </ButtonGroup>
                </h3>
                <br style={{clear:'both'}} />
                <Row className={'p-2'} style={{background: '#E5E5E5'}}>
                    <Col className={'col-2 pr-1'}>

                        <Row>
                            <Col>
                                <Button variant="primary" className={'ml-2 prevforButton'}
                                        onClick={() => setYear(year - 1)}>
                        <span className="material-icons" style={{fontSize: '12px'}}>
                            arrow_back_ios
                        </span>
                                </Button>
                            </Col>
                            <Col>
                                <p className={'pt-2'}>{year}</p>
                            </Col>
                            <Col>
                                <Button variant="primary" className={'ml-2 mr-2 prevforButton'}
                                        onClick={() => setYear(year + 1)}>
                        <span className="material-icons" style={{fontSize: '12px'}}>
                            arrow_forward_ios
                        </span>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col className={'col-8 pl-1 pr-1 text-center'} style={{marginLeft: '-15px'}}>
                        <ListGroup horizontal>
                            {MonthHoler}
                        </ListGroup>
                    </Col>

                    <Col className={'col-2'}>
                        <Row>
                            <Col className={'pl-1 pr-1 pt-2'}>

                                <Form.Check
                                    onChange={(e) => setWorking(e.target.checked)}
                                    checked={working}
                                    className={'float-start mr-2'}
                                    label={IntlMessageValue('de', 'wtp.working', Store)}
                                    name="group1"
                                    type={'checkbox'}
                                    id={`reverse-1`}
                                />


                                <Form.Check
                                    onChange={(e) => setSick(e.target.checked)}
                                    checked={sick}
                                    className={'float-start  mr-2'}
                                    label={IntlMessageValue('de', 'wtp.sick', Store)}
                                    name="group1"
                                    type={'checkbox'}
                                    id={`reverse-2`}
                                />


                                <Form.Check
                                    onChange={(e) => setVacation(e.target.checked)}
                                    checked={vacation}
                                    className={'float-start  mr-2'}
                                    label={IntlMessageValue('de', 'wtp.vacation', Store)}
                                    name="group1"
                                    type={'checkbox'}
                                    id={`reverse-3`}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>
                            <Row>
                                <Col>
                                    <Form.Select aria-label="Default select example" id={'SelectMode'}
                                                 style={{display: (editMode === true ? 'block' : 'none')}}
                                                 onChange={(e) => Changer(e)}>
                                        <option value="working">{IntlMessageValue('de', 'wtp.working', Store)}</option>
                                        <option value="vacation">{IntlMessageValue('de', 'wtp.vacation', Store)}</option>
                                        <option value="sick">{IntlMessageValue('de', 'wtp.sick', Store)}</option>
                                        <option
                                            value="unapologetic">{IntlMessageValue('de', 'wtp.unapologetic', Store)}</option>
                                        <option value="absent">{IntlMessageValue('de', 'wtp.absent', Store)}</option>
                                        <option value="training">{IntlMessageValue('de', 'wtp.training', Store)}</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Select aria-label="Default select example" id={'SelectCommit'}
                                                 style={{display: 'none'}}>
                                        <option label={'Choose reason'}/>
                                        <option
                                            value="parental_leave">{IntlMessageValue('de', 'wtp.parental_leave', Store)}</option>
                                        <option
                                            value="illness_and_doctor_visits">{IntlMessageValue('de', 'wtp.illness_and_doctor_visits', Store)}</option>
                                        <option
                                            value="family_events">{IntlMessageValue('de', 'wtp.family_events', Store)}</option>
                                        <option
                                            value="caring_for_close_relatives">{IntlMessageValue('de', 'wtp.caring_for_close_relatives', Store)}</option>
                                        <option
                                            value="relocation">{IntlMessageValue('de', 'wtp.relocation', Store)}</option>
                                        <option
                                            value="public_duties">{IntlMessageValue('de', 'wtp.public_duties', Store)}</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </th>
                        {TheadHolder}
                    </tr>
                    </thead>
                    <tbody>
                    {USerRow}
                    </tbody>
                </Table>

            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export default Planer;
