import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import './Login.css'
import {
    fetchDataApiUser,
    saveDataApiToken
} from '../../../../core/RestFullApi/ApiHook'
import { notify_error_Message } from '../../../../core/component/const/notification.service'
import { error } from '../../../../core/component/const/error.services'
import { useSelector } from 'react-redux'

const Login = (props: { value: any }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [logins, setLogin] = useState(0)
    const [clearStore, setClearStore] = useState(
        localStorage.getItem('clearStore') !== null
            ? localStorage.getItem('clearStore')
            : 'false'
    )
    const [checkLoad, setCheckLoad] = useState(
        localStorage.getItem('checkLoad') !== null
            ? localStorage.getItem('checkLoad')
            : 'false'
    )
    let Store = useSelector((state: any) => state)
    const validateForm = () => {
        return email.length > 0 && password.length > 0
    }

    if (checkLoad === 'false') {
        if (
            localStorage.getItem('author') !== null ||
            localStorage.getItem('Blog_searchValue_search_title') !== null ||
            localStorage.getItem('Blog_searchValue') !== null ||
            localStorage.getItem('Blog_searchValue_search_name') !== null ||
            localStorage.getItem('searchEnterInput') !== null
        ) {
            setClearStore('false')
            localStorage.setItem('clearStore', 'false')
            if (clearStore === 'false') {
                localStorage.clear()
            }
        } else {
            localStorage.setItem('clearStore', 'true')
        }

        localStorage.setItem('checkLoad', 'true')
        setCheckLoad('true')
    }

    localStorage.setItem('Logout', '1')
    const HandleSubmit = (e: {
        preventDefault: () => void
        stopPropagation: () => void
    }) => {
        e.preventDefault()
        e.stopPropagation()

        var raw = JSON.stringify({
            email: email,
            password: password
        })
        saveDataApiToken('/Auth/token/', raw)
            .then((data: any) => {
                var raw = JSON.stringify({
                    email: email
                })
                localStorage.setItem('token', JSON.stringify(data.access))
                fetchDataApiUser('/token/user/', data.access, raw)
                    .then((datas: any) => {
                        let user
                        let userRole
                        if (datas[0].hasOwnProperty('name')) {
                            user = datas[0].name
                        } else if (datas[0].hasOwnProperty('username')) {
                            user = datas[0].username
                        }
                        if (datas[0].hasOwnProperty('usergroup')) {
                            userRole = datas[0].usergroup
                        } else if (datas[0].hasOwnProperty('roles')) {
                            userRole = JSON.parse(datas[0].roles)[1]
                        }
                        localStorage.setItem('locale', (datas[0].locale_code !== null ? datas[0].locale_code  : 'DE'))
                        localStorage.setItem('Logout', '2')
                        localStorage.setItem('username', user)
                        localStorage.setItem('avatar', datas[0].media)
                        localStorage.setItem('userid', datas[0].id)
                        localStorage.setItem('userRole', userRole)
                        localStorage.setItem('email', datas[0].email)
                        localStorage.setItem('first_name', datas[0].first_name)
                        localStorage.setItem('last_name', datas[0].last_name)

                        setLogin(1)
                        let landingpages: string
                        if (process.env.REACT_APP_LANDINGPAGES !== undefined) {
                            landingpages = process.env
                                .REACT_APP_LANDINGPAGES as string
                        } else {
                            landingpages = '/Blog/1'
                        }

                        setTimeout(
                            () => (window.location.href = landingpages),
                            1000
                        )
                    })
                    .catch((e: any) => {
                        //error(e)
                        localStorage.setItem('Logout', '2')
                        let landingpages: string
                        landingpages = '/Blog/1'
                        setTimeout(
                            () => (window.location.href = landingpages),
                            1000
                        )
                    })
            })
            .catch((e) => {
                error(e)
                notify_error_Message(e.response.data.msg, Store)
            })
    }

    if (logins === 1) {
        return <></>
    } else if (logins === 0) {
        if (process.env.REACT_APP_LOGO_IMG !== '/uni_logo.png') {
            return (
                <div className="Login" style={{ paddingBottom: '2em' }}>
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={process.env.REACT_APP_LOGO_IMG}
                            alt={''}
                            style={{ maxHeight: '120px' }}
                        />
                    </div>
                    <br />
                    <Form
                        id={'form001'}
                        noValidate
                        onSubmit={(e) => HandleSubmit(e)}
                    >
                        <Form.Group controlId="email">
                            <Form.Control
                                autoFocus
                                type="email"
                                value={email}
                                className={'inputField'}
                                placeholder={'Email Adress'}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <br />
                        <Form.Group controlId="password">
                            <Form.Control
                                type="password"
                                value={password}
                                placeholder={'Password'}
                                className={'inputField'}
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <span
                                onClick={clickHandler}
                                className="material-icons viewpw"
                            >
                                visibility
                            </span>
                        </Form.Group>
                        <br />
                        <Button
                            size="lg"
                            type="submit"
                            className={'LoginField'}
                            disabled={!validateForm()}
                        >
                            Login
                        </Button>
                    </Form>
                    {process.env.REACT_APP_Register !== undefined ? (
                        <p className={'logintext undertext'}>
                            Dont`t have an account?{' '}
                            <a href={'/Signin'}>Register now</a> | forgot
                            Password?
                        </p>
                    ) : (
                        <></>
                    )}
                    {process.env.REACT_APP_PARTNER_LOGO !== undefined ? (
                        <div>
                            <img
                                src={process.env.REACT_APP_PARTNER_LOGO}
                                alt={''}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {process.env.REACT_APP_IMPRESSUM !== undefined ? (
                        <p style={{ height: '20px' }}>
                            <a
                                className={'text-white float-end btn btn-info'}
                                href={'/impressum.html'}
                            >
                                Impressum
                            </a>
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
            )
        } else {
            return (
                <div className="Login">
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={process.env.REACT_APP_LOGO_IMG}
                            alt={''}
                            style={{ maxHeight: '120px' }}
                        />
                    </div>
                    <p className={'logintext'}>
                        <br />
                        {process.env.REACT_APP_INDI_LOGIN_TEXT !== undefined
                            ? process.env.REACT_APP_INDI_LOGIN_TEXT
                            : 'Create your own digital space without restrictions and immerse yourself in the world of modern design.'}

                        <br />
                        <br />
                    </p>
                    <Form
                        id={'form001'}
                        noValidate
                        onSubmit={(e) => HandleSubmit(e)}
                    >
                        <Form.Group controlId="email">
                            <Form.Control
                                autoFocus
                                type="email"
                                value={email}
                                className={'inputField'}
                                placeholder={'Email Adress'}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <br />
                        <Form.Group controlId="password">
                            <Form.Control
                                type="password"
                                value={password}
                                placeholder={'Password'}
                                className={'inputField'}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                                onClick={clickHandler}
                                className="material-icons viewpw"
                            >
                                visibility
                            </span>
                        </Form.Group>

                        <br />
                        <Button
                            size="lg"
                            type="submit"
                            id={'login'}
                            className={'LoginField'}
                            disabled={!validateForm()}
                        >
                            Login
                        </Button>
                        {process.env.REACT_APP_Register !== undefined ? (
                            <p className={'logintext undertext'}>
                                Dont`t have an account?{' '}
                                <a href={'/Signin'}>Register now</a> | <a href={'/forgottpassword'}>forgot Password?</a>
                            </p>
                        ) : (
                            <></>
                        )}
                        {process.env.REACT_APP_PARTNER_LOGO !== undefined ? (
                            <div>
                                <img
                                    src={process.env.REACT_APP_PARTNER_LOGO}
                                    alt={''}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        {/*<p className={'logintext undertext'}>Dont`t have an account? Register now | forgot Password?</p>*/}
                    </Form>
                    {process.env.REACT_APP_IMPRESSUM !== undefined ? (
                        <p style={{ height: '20px' }}>
                            <a
                                className={'text-white float-end btn btn-info'}
                                href={'/impressum.html'}
                            >
                                Impressum
                            </a>
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
            )
        }
    } else {
        if (process.env.REACT_APP_LOGO_IMG !== '/uni_logo.png') {
            return (
                <div className="Login" style={{ paddingBottom: '2em' }}>
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={process.env.REACT_APP_LOGO_IMG}
                            alt={''}
                            style={{ maxHeight: '120px' }}
                        />
                    </div>
                    <br />
                    <Form
                        id={'form001'}
                        noValidate
                        onSubmit={(e) => HandleSubmit(e)}
                    >
                        <Form.Group controlId="email">
                            <Form.Control
                                autoFocus
                                type="email"
                                value={email}
                                className={'inputField'}
                                placeholder={'Email Adress'}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <br />
                        <Form.Group controlId="password">
                            <Form.Control
                                type="password"
                                value={password}
                                placeholder={'Password'}
                                className={'inputField'}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                                onClick={clickHandler}
                                className="material-icons viewpw"
                            >
                                visibility
                            </span>
                        </Form.Group>
                        <br />
                        <Button
                            size="lg"
                            type="submit"
                            className={'LoginField'}
                            disabled={!validateForm()}
                        >
                            Login
                        </Button>
                        {process.env.REACT_APP_Register !== undefined ? (
                            <p className={'logintext undertext'}>
                                Dont`t have an account?{' '}
                                <a href={'/Signin'}>Register now</a> | forgot
                                Password?
                            </p>
                        ) : (
                            <></>
                        )}
                        {process.env.REACT_APP_PARTNER_LOGO !== undefined ? (
                            <div>
                                <img
                                    src={process.env.REACT_APP_PARTNER_LOGO}
                                    alt={''}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </Form>
                    {process.env.REACT_APP_IMPRESSUM !== undefined ? (
                        <p style={{ height: '20px' }}>
                            <a
                                className={'text-white float-end btn btn-info'}
                                href={'/impressum.html'}
                            >
                                Impressum
                            </a>
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
            )
        } else {
            return (
                <div className="Login">
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={process.env.REACT_APP_LOGO_IMG}
                            alt={''}
                            style={{ maxHeight: '120px' }}
                        />
                    </div>
                    <p className={'logintext'}>
                        <br />
                        {process.env.REACT_APP_INDI_LOGIN_TEXT !== undefined
                            ? process.env.REACT_APP_INDI_LOGIN_TEXT
                            : 'Create your own digital space without restrictions and immerse yourself in the world of modern design.'}
                        <br />
                        <br />
                    </p>
                    <Form
                        id={'form001'}
                        noValidate
                        onSubmit={(e) => HandleSubmit(e)}
                    >
                        <Form.Group controlId="email">
                            <Form.Control
                                autoFocus
                                type="email"
                                value={email}
                                className={'inputField'}
                                placeholder={'Email Adress'}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Control
                                type="password"
                                value={password}
                                id={'password'}
                                placeholder={'Password'}
                                className={'inputField'}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                                onClick={clickHandler}
                                className="material-icons viewpw"
                            >
                                visibility
                            </span>
                        </Form.Group>
                        <br />
                        <Button
                            size="lg"
                            type="submit"
                            className={'LoginField'}
                            disabled={!validateForm()}
                        >
                            Login
                        </Button>
                        {process.env.REACT_APP_Register !== undefined ? (
                            <p className={'logintext undertext'}>
                                Dont`t have an account?{' '}
                                <a href={'/Signin'}>Register now</a> | forgot
                                Password?
                            </p>
                        ) : (
                            <></>
                        )}
                        {process.env.REACT_APP_PARTNER_LOGO !== undefined ? (
                            <div>
                                <img
                                    src={process.env.REACT_APP_PARTNER_LOGO}
                                    alt={''}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </Form>
                    {process.env.REACT_APP_IMPRESSUM !== undefined ? (
                        <p style={{ height: '20px' }}>
                            <a
                                className={'text-white float-end btn btn-info'}
                                href={'/impressum.html'}
                            >
                                Impressum
                            </a>
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
            )
        }
    }
}

export default Login

const clickHandler = () => {
    let passwortCointainer = 'password'
    let password = document.getElementById(
        passwortCointainer
    ) as HTMLInputElement

    if (password.type === 'password') {
        password.type = 'text'
    } else {
        password.type = 'password'
    }
}
