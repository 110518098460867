
import Badge from 'react-bootstrap/Badge';
import {ResponseOverview} from "./API/response/Type/customizer";
import {useNavigate, useParams} from "react-router-dom";
import React, {useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {ApiURL, IntlMessageValue} from "../../../@WUM/core/Elements/index";
import {handleSucces} from "../../../@WUM/core/component/const/HandleSucces";
import PaginationJSX from "../../../@WUM/core/form-serilizer/elements/pagination";
import SearchJSX from "../../../@WUM/core/component/const/SearchJSX";
import {useSelector} from "react-redux";
import {SpinnerJSX} from "../../../@WUM/core/component/const/Spinner";
import Col from "react-bootstrap/Col";

const PaymentHistorytOverview = (props: { value: any }) => {
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    let limit: any
    const [searchValue, setSearchValue] = useState('')
    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    }
    const submit = (e:any,id:any,parameter:any) => {
        e.preventDefault();
        let data = {
            parameter:parameter,
            value: e.target.value
        };
        let raw = JSON.stringify(data);
        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };
        fetch(root+"update/parameter/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => Notification({rewrite:true,landingpages:'#',type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4}))
            .catch(error => Notification({rewrite:true,landingpages:'#',type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4}));

    }


    const response = ResponseOverview(limit, searchValue)
    return(<>
        <div id="wrapperContent">
            <BuildTodo ressponse={response} submit={submit} />
        </div>
    </>

    )

}

export default PaymentHistorytOverview;

const BuildTodo = (props:{ressponse:any,submit:any}) =>{
    const Store = useSelector((state: any) => state)
    const { page } = useParams<{ page: string }>()
    let limit: any
    if (typeof page === 'string') {
        limit = (parseFloat(page) - 1) * 10
    }

    const response = props.ressponse
    const history = useNavigate()
    const destination = 'payment_history'
    const namespace_search = 'payment_history_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    let Cards = [];
    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'Name',
                    param: 'customer',
                    method: 'like'
                },
                {
                    id: 'search_name',
                    translation: 'Rechnungsnummer',
                    param: 'invoiceNr',
                    method: 'like'
                },
                {
                    id: 'search_name',
                    translation: 'Typ',
                    param: 'type',
                    method: 'like'
                },
                {
                    id: 'search_name',
                    translation: 'Firma',
                    param: 'company',
                    method: 'like'
                },
                {
                    id: 'search_title',
                    translation: 'common.date',
                    param: 'filter',
                    method: '=',
                    individual: (
                        <Form.Group
                            as={Col}
                            controlId={'search_title'}
                            name={'search_title'}
                        >
                            <Form.Label>Nach Titel Sortieren</Form.Label>
                            <Form.Select
                                aria-label="Filter"
                                defaultValue={
                                    localStorage.getItem(
                                        namespace_search + '_search_title'
                                    ) as string
                                }
                            >
                                <option />
                                <option value={'title'}>
                                    {IntlMessageValue(
                                        'de',
                                        'searchadded.title.up',
                                        Store
                                    )}
                                </option>
                                <option value={'-title'}>
                                    {IntlMessageValue(
                                        'de',
                                        'searchadded.title.down',
                                        Store
                                    )}
                                </option>
                            </Form.Select>{' '}
                        </Form.Group>
                    )
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'Neue News erstellen'
            ]
        }
    ]

    const SelectField = (state:any) => {

        return (
            <>
                <Form.Select name={'value'} aria-label="Default select example" value={state}>
                    <option value="0">Wartend</option>
                    <option value="1">Bezahlt</option>
                </Form.Select>
            </>
        )

    }

    for(let x=1;x<props.ressponse.length;x++) {
        let openTodos = 0;
        let closeTodos = 0;
        let State = '';
        let color;


        if (props.ressponse[x].states !== null) {
            if (props.ressponse[x].states.dunningLetter !== 1) {
                openTodos++;
            } else {
                closeTodos++
                State = 'Zahlungserinnerung';
                color = 'PaymentHistroyYellow'
            }
            if (props.ressponse[x].states.invoicePayed !== 1) {
                openTodos++;
            } else {
                closeTodos++
                State = 'Rechnung gezahlt';
                color = 'PaymentHistroyGreen'
            }
            if (props.ressponse[x].states.invoiceSent !== 1) {
                openTodos++;
            } else {
                closeTodos++
                State = 'Rechnung versendet';
                color = 'PaymentHistroyYellow'
            }
            if (props.ressponse[x].states.notProcessed !== 1) {
                openTodos++;
            } else {
                closeTodos++
                State = 'Nicht bearbeitet';
                color = 'PaymentHistroyRed'
            }

            if (props.ressponse[x].states.overdue !== 1) {
                openTodos++;
            } else {
                closeTodos++
                State = 'Überfällig';
                color = 'PaymentHistroyYellow'
            }
            if (props.ressponse[x].states.paymentReminder !== 1) {
                openTodos++;
            } else {
                let payd = SelectField(0)
                closeTodos++
                State = 'Mahnung'
                color = 'PaymentHistroyYellow'
            }
        } else {
            openTodos = 5;
            closeTodos = 1;
            State = 'Nicht bearbeitet'
            color = 'PaymentHistroyRed'
        }

        Cards.push(
            <>

                    <tr className={color}>
                        <td>{props.ressponse[x].type.toUpperCase()}</td>
                        <td>{props.ressponse[x].invoiceNr}</td>
                        <td>{props.ressponse[x].date}</td>
                        <td>{(props.ressponse[x].address !== null ? props.ressponse[x].address.company : props.ressponse[x].dcompany)}</td>
                        <td>{(props.ressponse[x].address !== null ?props.ressponse[x].address.first_name : props.ressponse[x].dfirstname)} {(props.ressponse[x].address !== null ?props.ressponse[x].address.last_name : props.ressponse[x].dlastname)}</td>
                        <td><div className={'text-right'}><Badge bg="danger" style={{marginLeft: '10px'}} title={'Offen'}>{openTodos}</Badge>
                            <Badge bg="success" style={{marginLeft: '10px'}} title={'Erledigt'}>{closeTodos}</Badge>
                        </div></td>
                        <td>{createBadge((props.ressponse[x].states !== null ? props.ressponse[x].states.notProcessed  : 0))}</td>
                        <td>{createBadge((props.ressponse[x].states !== null ? props.ressponse[x].states.invoiceSent : 0))}</td>
                        <td>{createBadge((props.ressponse[x].states !== null ? props.ressponse[x].states.overdue : 0))}</td>
                        <td>{createBadge((props.ressponse[x].states !== null ? props.ressponse[x].states.dunningLetter : 0))}</td>
                        <td>{createBadge((props.ressponse[x].states !== null ? props.ressponse[x].states.paymentReminder : 0))}</td>
                        <td>{createBadge((props.ressponse[x].states !== null ? props.ressponse[x].states.invoicePayed : 0))}</td>
                        <td>{(props.ressponse[x].states !== null && props.ressponse[x].states.invoiceSent === 1 ? SelectField(0):  <>
                        <div className={'text-right'}> <span className="material-icons" title={'In Bearbeitung'}>
                            pending_actions
                        </span></div>
                        </>)}</td>

                    </tr>


            </>
        )
    }
    if (response.length > 0) {
        let heads = [
            {
                name: 'Art',
                icon: 'account_balance',
            },
            {
                name: 'Nummer',
                icon: 'pin',
            },
            {
                name: 'Datum',
                icon: 'calendar_month',
            },
            {
                name: 'Firma',
                icon: 'business',
            },
            {
                name: 'Inhaber/GF',
                icon: 'contacts',
            },
            {
                name: 'Todo',
                icon: 'task',
            },
            {
                name: 'Bearbeitet',
                icon: 'work',
            },
            {
                name: 'Versendet',
                icon: 'send',
            },
            {
                name: 'Überfällig',
                icon: 'notifications_active',
            },
            {
                name: 'Erinnerung',
                icon: 'notification_important',
            },
            {
                name: 'Mahnung',
                icon: 'credit_card_off',
            },
            {
                name: 'Gezahlt',
                icon: 'savings',
            }
        ]
    return (
          <> <SearchJSX
              setSearchValue={setSearchValue}
              Store={Store}
              noNewButton={true}
              SearchConfig={SearchConfig}
          />
              <Table striped bordered hover id={'TablePaymentHistory'}>
                  <thead>

                  <tr>
                      {CreateTableHeader(heads)}
                      <th>Status<span className="material-icons" style={{verticalAlign: 'bottom',float:'right'}}>
                        info
                        </span></th>

                  </tr>
                  </thead>
                  <tbody>
              {Cards}
                  </tbody>
              </Table>
              <PaginationJSX
                  response={response}
                  history={history}
                  to={'overview'}
                  handleSuccess={handleSucces}
                  destination={destination}
              />
          </>
    )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}


const createBadge = (state:any) => {
    if(state === 1) {
        return (
            <>
                <div className={'text-right '}><Badge bg="success" title={'Erledigt'} style={{width:'25px'}}>&#10003;</Badge><br /></div>
            </>
        )
    } else if(state === 2) {
        return (<>
                <div className={'text-right '}><Badge bg="warning" title={'Teilweise'} style={{width:'25px',textAlign:'left',padding:'5px',background: '#FFFF00'}}>&#9201;</Badge><br /></div>
        </>
        )
    } else {
        return (
            <>
                <div className={'text-right'}><Badge bg="danger" title={'Offen'} style={{width:'25px'}}>&#10007;</Badge><br /></div>
            </>
        )
    }



}


const createState = (value:any) => {
    return (
        <>
            <div className={'text-right'}><Badge bg="success">{value}</Badge><br /></div>
        </>
    )

}

export const CreateTableHeader = (header:any) => {

    let THHolder = [];

    for(let x=0;x<header.length;x++) {
        THHolder.push(
            <th>{header[x].name}<span className="material-icons" style={{verticalAlign: 'bottom',float:'right'}}>
                        {header[x].icon}
                        </span></th>
        )
    }

    return(
        <>
                {THHolder}

        </>
    )

}

export const CreateTd = () => {

}
