import Card from 'react-bootstrap/Card';
import {CSSProperties, ElementType,JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";

interface UniCardsImgOverlayProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** You can use a custom element type for this component. */
    as?:ElementType<any> | undefined
    /** Set src on Element */
    src?:string
    /** Set custom Classes on Element */
    class?:string
    /** Set id on Element in DOM */
    id?: string,
    /** Set inline style for Element */
    style?:CSSProperties | undefined
}

export const UniCardsImgOverlay = (props:UniCardsImgOverlayProps) => {

    return(
        <>
            <Card.ImgOverlay as={props.as} src={props.src} id={props.id} className={props.class}  style={props.style} />
        </>
    )
}
